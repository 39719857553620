<template>
    <div>
        <div v-if="isLoading" class="l-stack l-gap-3 l-padded">
            <Spinner size="medium" line-fg-color="#000" :speed="1" />
        </div>

        <template v-else>
            <asset-image-control
                v-if="!tracker.asset_details.external_picture"
                :picture-url="tracker.asset_details.picture"
                :type-identifier="tracker.asset_details.asset_type_identifier"
                @change="assetPicture = $event"
            />

            <div class="l-new-asset-view l-stack l-gap-5 l-padded">
                <div class="l-stack l-gap-2">
                    <form-input
                        v-model="$v.tracker.asset_details.name.$model"
                        :label="$t('name')"
                        :required="true"
                        :show-required-error="
                            $v.tracker.asset_details.name.$dirty &&
                                !$v.tracker.asset_details.name.required
                        "
                    />

                    <form-input
                        v-model="tracker.asset_details.identification"
                        :label="$t('identification')"
                    />

                    <div>
                        <div class="form-label">
                            {{ $t('location') }}
                        </div>

                        <location-select
                            v-model="tracker.location"
                            :placeholder="$t('locationSelectplaceholder')"
                            :removable="true"
                        />
                    </div>
                </div>

                <div class="l-stack l-gap-2">
                    <h3 class="t-title">{{ $t('lookOnMap') }}</h3>

                    <color-input
                        v-model="tracker.asset_details.color"
                        :label="$t('color')"
                    />

                    <div>
                        <div class="form-label">
                            {{ $t('type') }}
                        </div>

                        <asset-type-select
                            v-model="tracker.asset_details.asset_type"
                        />
                    </div>
                </div>

                <div
                    v-if="initialValues.runningTime !== undefined"
                    class="l-stack l-gap-2"
                >
                    <h3 class="t-title">{{ $t('measurements') }}</h3>

                    <label>
                        <p class="form-label">{{ $t('runningTime') }}</p>

                        <div class="l-inline l-gap-1">
                            <base-input v-model="runningHours" :block="false">
                                <template #postfix>h</template>
                            </base-input>

                            <base-input v-model="runningMinutes" :block="false">
                                <template #postfix>m</template>
                            </base-input>
                        </div>
                    </label>
                </div>

                <div v-if="isAdminOrStaff" class="l-stack l-gap-2">
                    <h3 class="t-title">{{ $t('rights') }}</h3>

                    <div>
                        <div class="form-label">
                            {{ $t('usersViewingAccess') }}
                        </div>

                        <user-select
                            v-model="usersViewingAccess"
                            :exclude="[
                                tracker.asset_details.owner,
                                ...usersEditingAccess,
                            ]"
                            :multiple="true"
                        />
                    </div>

                    <div>
                        <div class="form-label">
                            {{ $t('usersEditingAccess') }}
                        </div>

                        <user-select
                            v-model="usersEditingAccess"
                            :exclude="[
                                tracker.asset_details.owner,
                                ...usersViewingAccess,
                            ]"
                            :multiple="true"
                        />
                    </div>

                    <v-dialog></v-dialog>
                </div>

                <div
                    v-if="isStatic && showLocationEdit"
                    class="l-stack l-gap-2"
                >
                    <h3 class="t-title">{{ $t('changeAssetPosition') }}</h3>

                    <p class="t-small t-subtle">
                        {{ $t('changeAssetPositionDescription') }}
                    </p>

                    <div>
                        <div class="form-label">
                            {{ $t('changeStaticPositionToAdress') }}
                        </div>

                        <div class="search-box">
                            <address-search
                                :proximity-longitude="
                                    tracker.asset_details.position.longitude
                                "
                                :proximity-latitude="
                                    tracker.asset_details.position.latitude
                                "
                                @input="setAddress"
                            />
                        </div>
                    </div>
                </div>

                <FormNavigation @save="save" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Spinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'

import AddressSearch from '../AddressSearch'
import AssetImageControl from '../AssetImageControl'
import AssetTypeSelect from '../AssetTypeSelect'
import BaseInput from '../redesigned/BaseInput'
import ColorInput from '../ColorInput'
import FormInput from '../FormInput'
import FormNavigation from '../FormNavigation'
import LocationSelect from '../LocationSelect'
import UserSelect from '../UserSelect'

export default {
    name: 'EditAssetView',
    components: {
        AddressSearch,
        AssetImageControl,
        AssetTypeSelect,
        BaseInput,
        ColorInput,
        FormInput,
        FormNavigation,
        LocationSelect,
        Spinner,
        UserSelect,
    },
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
        shoudRedirectOnSave: {
            type: Boolean,
            default: true,
        },
        showLocationEdit: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            assetPicture: null,
            isLoading: true,
            runningHours: null,
            runningMinutes: null,
            usersViewingAccess: null,
            usersEditingAccess: null,
            address: null,
            changesAccepted: false,
            initialValues: {},
            tracker: null,
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.changesAccepted || !this.changed) {
            next()
            return
        }

        this.$modal.show('dialog', {
            title: this.$t('warning'),
            text: this.$t('changesUnsaved'),
            buttons: [
                {
                    title: this.$t('shared.yes'),
                    handler: () => {
                        // Reset tracker location
                        this.updateAsset({
                            id: this.tracker.asset_details.id,
                            position: this.initialValues.position,
                        })

                        if (
                            to.name !== 'detail' &&
                            to.params.id !== this.tracker.asset
                        ) {
                            this.setActiveTrackerOnMap(null)
                        }

                        this.$modal.hide('dialog')
                        next()
                    },
                },
                {
                    title: this.$t('shared.no'),
                },
            ],
        })
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapState('map', ['mapInstance']),
        ...mapGetters('authentication', ['isAdminOrStaff']),
        assetId() {
            return this.tracker.asset
        },
        isStatic() {
            return this.tracker.asset_details.static
        },
        position() {
            return this.trackers.find(item => item.id == this.id).asset_details
                .position
        },
        changed() {
            return (
                this.initialValues.usersEditingAccess !==
                    this.usersEditingAccess ||
                this.initialValues.usersViewingAccess !==
                    this.usersViewingAccess ||
                this.initialValues.position.longitude !==
                    this.position.longitude ||
                this.initialValues.position.latitude !==
                    this.position.latitude ||
                this.initialValues.name !== this.tracker.asset_details.name ||
                this.initialValues.picture !==
                    this.tracker.asset_details.picture ||
                this.initialValues.identification !==
                    this.tracker.asset_details.identification ||
                this.initialValues.assetType !==
                    this.tracker.asset_details.asset_type ||
                this.initialValues.color !== this.tracker.asset_details.color ||
                this.initialValues.location !== this.tracker.location ||
                this.initialValues.runningTime !==
                    (this.runningHours * 60 + this.runningMinutes) * 60
            )
        },
    },
    watch: {
        id() {
            this.loadData()
        },
    },
    mounted() {
        this.loadData()
    },
    methods: {
        ...mapMutations('tracker', [
            'setActiveTrackerOnMap',
            'updateAsset',
            'updateTracker',
        ]),
        async loadData() {
            this.tracker = JSON.parse(
                JSON.stringify(this.trackers.find(item => item.id == this.id))
            ) // Making deep copy

            this.initialValues.position = this.tracker.asset_details.position
            this.initialValues.name = this.tracker.asset_details.name
            this.initialValues.picture = this.tracker.asset_details.picture
            this.initialValues.identification = this.tracker.asset_details.identification
            this.initialValues.assetType = this.tracker.asset_details.asset_type
            this.initialValues.color = this.tracker.asset_details.color
            this.initialValues.location = this.tracker.location
            this.initialValues.runningTime = this.tracker.asset_details.sensor_data.running_time?.value
            this.setActiveTrackerOnMap(this.tracker)

            if (typeof this.initialValues.runningTime === 'number') {
                const { runningTime } = this.initialValues
                this.runningHours = Math.floor(runningTime / 3600)
                this.runningMinutes =
                    Math.round(runningTime / 60) - this.runningHours * 60
            }

            if (this.isAdminOrStaff) {
                this.isLoading = true
                await this.loadRights()
            }

            this.isLoading = false
        },
        async loadRights() {
            const { data } = await httpHelper.get(
                `/asset-permissions/?asset=${this.assetId}`
            )

            this.usersViewingAccess = data.results
                .filter(item => item.access === 'READ')
                .map(item => item.user)

            this.usersEditingAccess = data.results
                .filter(item => item.access === 'WRITE')
                .map(item => item.user)

            this.initialValues.usersViewingAccess = this.usersViewingAccess
            this.initialValues.usersEditingAccess = this.usersEditingAccess
        },
        isValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
        setAddress(addressObject) {
            if (!addressObject?.geometry?.coordinates) {
                return
            }

            const [longitude, latitude] = addressObject.geometry.coordinates
            this.updateAsset({
                id: this.tracker.asset_details.id,
                position: { latitude, longitude },
            })
            this.mapInstance.setView([latitude, longitude], 16)
        },
        async save() {
            if (!this.isValid()) {
                return
            }
            this.changesAccepted = true
            await httpHelper.patch(`/assets/${this.assetId}/`, {
                name: this.tracker.asset_details.name,
                identification: this.tracker.asset_details.identification,
                color: this.tracker.asset_details.color,
                asset_type: this.tracker.asset_details.asset_type,
                position: this.position,
            })

            if (this.assetPicture) {
                const payload = new FormData()
                payload.append(
                    'picture',
                    this.assetPicture.data,
                    this.assetPicture.name
                )
                await httpHelper.patch(`/assets/${this.assetId}/`, payload, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
            }

            if (typeof this.initialValues.runningTime === 'number') {
                const runningTime =
                    this.runningHours * 3600 + this.runningMinutes * 60
                if (this.initialValues.runningTime !== runningTime) {
                    this.tracker.asset_details.sensor_data = await httpHelper.patch(
                        `/assets/${this.tracker.asset}/sensor-data/`,
                        { running_time: runningTime }
                    )
                }
            }

            const { data: tracker } = await httpHelper.patch(
                `/trackers/${this.tracker.id}/`,
                {
                    location: this.tracker.location,
                }
            )

            if (this.isAdminOrStaff) {
                this.manageAccess()
            }

            this.updateTracker(tracker)
            this.$emit('saved')

            this.isLoading = false

            if (this.shoudRedirectOnSave) {
                this.$router.push({
                    name: 'detail',
                    params: { id: this.id },
                })
            }
        },
        async manageAccess() {
            const { data } = await httpHelper.get(
                `/asset-permissions/?asset=${this.assetId}`
            )

            const usersViewingAccessExisting = data.results
                .filter(item => item.access === 'READ')
                .map(item => item.user)

            const usersEditingAccessExisting = data.results
                .filter(item => item.access === 'WRITE')
                .map(item => item.user)

            data.results.forEach(item => {
                if (
                    item.access === 'READ' &&
                    this.usersEditingAccess.includes(item.user)
                ) {
                    usersEditingAccessExisting.push(item.user)
                    return httpHelper.patch(`/asset-permissions/${item.id}/`, {
                        access: 'WRITE',
                    })
                }

                if (
                    item.access === 'WRITE' &&
                    this.usersViewingAccess.includes(item.user)
                ) {
                    usersViewingAccessExisting.push(item.user)
                    return httpHelper.patch(`/asset-permissions/${item.id}/`, {
                        access: 'READ',
                    })
                }

                if (
                    (item.access === 'READ' &&
                        !this.usersViewingAccess.includes(item.user)) ||
                    (item.access === 'WRITE' &&
                        !this.usersEditingAccess.includes(item.user))
                ) {
                    return httpHelper.drop(`/asset-permissions/${item.id}/`)
                }
            })

            this.usersViewingAccess.forEach(user => {
                if (!usersViewingAccessExisting.includes(user)) {
                    httpHelper.post('/asset-permissions/', {
                        asset: this.assetId,
                        user,
                        access: 'READ',
                    })
                }
            })

            this.usersEditingAccess.forEach(user => {
                if (!usersEditingAccessExisting.includes(user)) {
                    httpHelper.post('/asset-permissions/', {
                        asset: this.assetId,
                        user,
                        access: 'WRITE',
                    })
                }
            })
        },
    },
    validations() {
        return {
            tracker: {
                asset_details: {
                    name: {
                        required,
                    },
                },
            },
        }
    },
}
</script>

<i18n>
    {
        "en": {
            "name": "Name",
            "identification": "Identification",
            "color": "Color",
            "type": "Type",
            "location": "Location",
            "locationSelectplaceholder": "Assign the tracker to a location",
            "measurements": "Measurements",
            "runningTime": "Operating hours",
            "usersViewingAccess": "Users with viewing access",
            "usersEditingAccess": "Users with editing access",
            "staticAsset": "Static asset",
            "setMarker": "Change marker map location",
            "changeStaticPositionToAdress": "Change the location of the asset to the following address:",
            "warning": "Warning!",
            "changesUnsaved": "Continue without saving?",
            "lookOnMap": "Appearance on the map",
            "rights": "Rights",
            "changeAssetPosition": "Change Asset Position",
            "changeAssetPositionDescription": "You can change the location of this asset on the map. To do so, simply drag the asset to the new location on the map. Alternatively, you can search and select an address in the following field. The asset will then be moved to that address."
        },
        "de": {
            "name": "Name",
            "identification": "Identifikation",
            "color": "Farbe",
            "type": "Typ",
            "location": "Standort",
            "locationSelectplaceholder": "Tracker einem Standort zuweisen",
            "measurements": "Messungen",
            "runningTime": "Betriebsstunden",
            "usersViewingAccess": "Benutzer mit Lesezugriff",
            "usersEditingAccess": "Benutzer mit Schreibzugriff",
            "staticAsset": "Statisches Asset",
            "setMarker": "Markerposition auf der Karte verändern",
            "changeStaticPositionToAdress": "Position des Assets zu folgender Adresse ändern:",
            "warning": "Warnung!",
            "changesUnsaved": "Weiterfahren ohne speichern?",
            "lookOnMap": "Aussehen auf der Karte",
            "rights": "Rechte",
            "changeAssetPosition": "Asset Position ändern",
            "changeAssetPositionDescription": "Sie können die Position dieses Assets auf der Karte ändern. Dazu ziehen Sie einfach das Asset mit der Maus auf der Karte zur neuen Position. Alternativ können Sie in folgendem Feld eine Adresse suchen und auswählen. Das Asset wird dann an diese Adresse verschoben."
        },
        "it": {
            "name": "Nome",
            "identification": "Identificazione",
            "color": "Colore",
            "type": "Typo",
            "location": "Locazione",
            "locationSelectplaceholder": "Locazione di Tracker",
            "measurements": "Misure",
            "runningTime": "Ore di funzionamento",
            "usersViewingAccess": "Utente con accesso in letturas",
            "usersEditingAccess": "Utente con accesso in scrittura",
            "staticAsset": "Risorsa statica",
            "setMarker": "Cambia la posizione dell'indicatore sulla mappa",
            "changeStaticPositionToAdress": "Cambiare la posizione del bene al seguente indirizzo:",
            "warning": "Attenzione!",
            "changesUnsaved": "Continua senza salvare?",
            "lookOnMap": "Aspetto sulla mappa",
            "rights": "Diritti",
            "changeAssetPosition": "Cambia posizione patrimoniale",
            "changeAssetPositionDescription": "È possibile modificare la posizione di questo asset sulla mappa. Per farlo, è sufficiente trascinare l'asset nella sua nuova posizione sulla mappa. In alternativa, è possibile cercare e selezionare un indirizzo nel seguente campo. Il bene sarà quindi spostato a quell'indirizzo."
        }
    }
</i18n>

<style lang="scss" scoped>
.checkbox {
    margin-right: 0.5rem;
}

.search-field {
    width: 85%;
    margin-right: 1rem;
}

.search-icon {
    cursor: pointer;
}

.search-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
</style>
